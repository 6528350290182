import React from 'react';
import classNames from 'classnames';
import * as classes from './Button.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faLock } from '@fortawesome/free-solid-svg-icons';

export interface ButtonProps {
    label: string;
    className?: string;
    onClick?: () => void;
    disabled?: boolean;
    spinner?: boolean;
}

const Button: React.FC<ButtonProps> = ({
    label,
    className,
    onClick,
    disabled,
    spinner
}) => {
    const containerStyles = classNames({
        [classes['button']]: true,
        [classes['buttonDisabled']]: disabled,
        [classes['buttonSpinner']]: spinner,
        [className]: className
    });

    // Handlers
    const handleClick = (e) => {
        e.preventDefault();

        if (typeof onClick !== 'function') {
            return;
        }

        onClick();
    }

    // Helpers
    const renderLabel = () => {
        if (spinner) {
            return (
                <FontAwesomeIcon icon={faCircleNotch} spin />
            );
        }

        if (disabled) {
            return (
                <FontAwesomeIcon icon={faLock} />
            );
        }

        return (
            <>
                { label }<span>.</span>
            </>
        )
    }

    return (
        <button className={containerStyles} onClick={handleClick} disabled={disabled || spinner}>
            <div className={classes['button__label']}>
                { renderLabel() }
            </div>
            <div className={classes['button__hover']} />
        </button>
    );
}

export { Button }