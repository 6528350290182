import { faInstagram, faLinkedinIn, faGithub } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import * as classes from './ContactSocial.module.scss';

const ContactSocial: React.FC = () => {
    return (
        <div className={classes['social']}>
            <a href={process.env.GATSBY_SOCIAL_INSTAGRAM} rel="noreferrer" target="_blank" className={classes['button']}>
                <div className={classes['button__label']}>
                    <FontAwesomeIcon icon={faInstagram} />
                </div>
                <div className={classes['button__hover']} />
            </a>
            <a href={process.env.GATSBY_SOCIAL_LINKEDIN} rel="noreferrer" target="_blank" className={classes['button']}>
                <div className={classes['button__label']}>
                    <FontAwesomeIcon icon={faLinkedinIn} />
                </div>
                <div className={classes['button__hover']} />
            </a>
            <a href={process.env.GATSBY_SOCIAL_GITHUB} rel="noreferrer" target="_blank" className={classes['button']}>
                <div className={classes['button__label']}>
                    <FontAwesomeIcon icon={faGithub} />
                </div>
                <div className={classes['button__hover']} />
            </a>
        </div>
    );
}

export { ContactSocial }