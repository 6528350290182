import React from 'react';
import * as classes from './ContactInfo.module.scss';

const ContactInfo: React.FC = () => {
    return (
        <div className={classes['info']}>
            <div className={classes['info__group']}>
                <div className={classes['info__label']}>
                    E-mail Address
                </div>
                <div className={classes['info__value']}>
                    { process.env.GATSBY_EMAIL }
                </div>
            </div>
            <div className={classes['info__group']}>
                <div className={classes['info__label']}>
                    LinkedIN
                </div>
                <div className={classes['info__value']}>
                    { process.env.GATSBY_SOCIAL_LINKEDIN }
                </div>
            </div>
        </div>
    );
}

export { ContactInfo }