import React from 'react';
import { DefaultLayout } from '../../layouts/default';
import { Helmet } from 'react-helmet';
import { SectionHeader } from '../../components/shared/section-header/SectionHeader';
import { ContactForm, ContactInfo, ContactSocial} from '../../components/contact';
import * as classes from './index.module.scss';

const Contact: React.FC = () => {
    return (
        <DefaultLayout>
            <Helmet>
                <title>Bravecode | Let's Work Together</title>
                <meta  name="description" content="Portfolio of Krzysztof Szymański known as bravecode. Explore Digital CV and detailed project descriptions. Let's work together!" />
            </Helmet>
            
            <main className={classes['page']}>
                
                <div className={classes['left']}>

                    <SectionHeader 
                        title="Let's work together"
                        description="Use form or contact me directly through social media."
                    />

                    <ContactForm />

                </div>


                <div className={classes['right']}>

                    <SectionHeader 
                        title="Contact Information"
                        description="& social media."
                        className={classes['infoHeader']}
                    />
                   
                    <ContactInfo />

                    <ContactSocial />

                </div>

            </main>
        </DefaultLayout>
    )
}

export default Contact;