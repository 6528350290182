import { ContactFormData } from "../ContactForm.type";

export interface EmailProviderProps {
  onSuccess: () => void;
  onError: (message: string) => void;
}

class EmailProvider {
  private onSucces: () => void;
  private onError: (message: string) => void;

  constructor (props: EmailProviderProps) {
    this.onSucces = props.onSuccess;
    this.onError = props.onError;
  }

  public send (data: ContactFormData) {
    if (!this.validate(data)) {
      return;
    }

    fetch(process.env.GATSBY_EMAIL_PROVIDER, {
      method: 'POST', 
      body: JSON.stringify(data),
      headers: {
          'Accept': 'application/json'
      }
    })
    .then(() => {
      this.onSucces();
    })
    .catch(() => {
      this.onError('Something went wrong.');
    });
  }

  public validate (data: ContactFormData) {
    if (!data.name || !data.email || !data.topic || !data.message) {
      return false;
    }

    // Validate E-mail
    if (!this.validateEmail(data.email)) {
      return false;
    }

    return true;
  }

  private validateEmail (value: string) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  }
}

export { EmailProvider };