import React, { useRef, useState, useEffect } from 'react';
import { EmailProvider } from './providers/emailProvider';
import { Button } from '../shared/ui/button/Button';
import { SubmitState, ContactFormData } from './ContactForm.type';
import * as classes from './ContactForm.module.scss';

const initialData: ContactFormData = {
    name: '',
    email: '',
    topic: '',
    message: ''
}

const ContactForm: React.FC = () => {
     // State
     const [submitState, setSubmitState] = useState<SubmitState>('disabled');
     const [data, setData] = useState<ContactFormData>(initialData);

    // Handlers
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setData(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        });
    }

    const handleSubmit = () => {
        setSubmitState('spinner');

        emailProvider.current.send(data);
    }

    const handleSuccess = () => {
        setSubmitState('disabled');
        setData(initialData);
    }

    const handleError = () => {
        alert('Something went wrong, please try again later');
        setSubmitState('disabled');
        setData(initialData);
    }

    // Email Provider
    const emailProvider = useRef<EmailProvider>(new EmailProvider({
        onSuccess: handleSuccess,
        onError: handleError
    }));

    useEffect(() => {
        if (submitState === 'spinner') {
            return;
        }

        emailProvider.current.validate(data)
            ? setSubmitState('enabled')
            : setSubmitState('disabled');
    }, [data])
    
    return (
        <form className={classes['form']}>
                        
            <div className={classes['form__group']}>
                <label htmlFor="name" className={classes['form__label']}>
                    Your Name
                </label>
                <input type="text" className={classes['form__input']} id="name" name="name" onChange={handleInputChange} value={data.name} />
            </div>

            <div className={classes['form__group']}>
                <label htmlFor="email" className={classes['form__label']}>
                    E-mail Address
                </label>
                <input type="email" className={classes['form__input']} id="email" name="email" onChange={handleInputChange} value={data.email} />
            </div>

            <div className={classes['form__group']}>
                <label htmlFor="topic" className={classes['form__label']}>
                    Topic
                </label>
                <input type="text" className={classes['form__input']} id="topic" name="topic" onChange={handleInputChange} value={data.topic} />
            </div>

            <div className={classes['form__group']}>
                <label htmlFor="content" className={classes['form__label']}>
                    Message
                </label>
                <textarea className={classes['form__textarea']} id="message" name="message" onChange={handleInputChange} value={data.message} />
            </div>

            <Button 
                label="Submit"
                onClick={handleSubmit}
                disabled={submitState === 'disabled'}
                spinner={submitState === 'spinner'}
            />

        </form>
    );
}

export { ContactForm }